<template>
    <section id="understanding-mobile" ref="infoProblem">
        <img
            class="img-bg"
            src="/img/foundry-safe/bg-blue-star.svg"
            alt=""                                            
        />
        <div class="utp__box-content-mobile">
            <div class="utp__wrap-title-mobile">
                <div class="utp-title-mobile">
                    We understand the problems
                </div>                
            </div>
            <div class="utp__wrap-img-mobile">
                <img
                    src="/img/foundry-safe/algo-star.png"
                    alt=""
                    :class="{active: slider === 0}"
                />
                <img
                    src="/img/foundry-safe/wallet-big.png"
                    alt=""                         
                    :class="{active: slider === 1}"    
                />
                <img                    
                    src="/img/foundry-safe/ledger.png"                                  
                    :class="{active: slider === 2}"
                />
            </div>
            <div class="utp__wrap-text-mobile">
                <div class="utp__box-text-mobile"                     
                    v-for="(item, index) in utp" :key="index"
                    :class="{active: index === slider}"
                >
                    <div class="utp__text-title-mobile">
                        {{item.title}}
                    </div>
                    <span>
                        {{item.subtitle}}
                    </span>                    
                </div>
            </div>
            <div class="control-slider">
                <div 
                    class="dot" 
                    :class="{active: slider === i}" 
                    v-for="(n, i) in utp" 
                    :key="i"
                    @click="slider = i"
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'foundy-safe-understanding',
    data() {
        return {
            slider: 0,
            utp:[
                {
                    title: 'Algorand Atomics',
                    subtitle:'Algorand network design and constraints leads to an architecture that relies heavily on atomic transactions. This reliance makes it difficult to use the built-in multi-sig capability of Algorand with DeFi projects.'
                },
                {
                    title: 'Hot Wallet & Key-Person Risk',
                    subtitle:'Hot wallets are prone to hacks and once compromised there are few options for recovery. Single signatures also introduce ‘key-person’ risk where everything is controlled and could be compromised by a single wallet.'                    
                },
                {
                    title: 'Ledger Lacking',
                    subtitle:'Hardware wallets like Ledger have few options for user interfaces that interact with the Algorand blockchain. MyAlgo was the most popular until it was compromised, and others are not deeply integrated with Dapps leading to a fractured ecosystem.'
                }
            ],
            xDown: null,
            yDown: null
        }
    },    
    methods:{
        getTouches(evt) {
            return evt.touches || evt.originalEvent.touches;
        },
        handleTouchStart(evt) {
            const firstTouch = this.getTouches(evt)[0];                                      
            this.xDown = firstTouch.clientX;                                      
            this.yDown = firstTouch.clientY;                                      
        },
        handleTouchMove(evt) {
            if ( ! this.xDown || ! this.yDown ) {
                return;
            }

            var xUp = evt.touches[0].clientX;                                    
            var yUp = evt.touches[0].clientY;

            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;
                                                                                
            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                if ( xDiff > 0 ) {
                    this.slider++;
                    if (this.slider > 2) this.slider = 0;
                } else {
                    this.slider--;
                    if (this.slider < 0) this.slider = 2;
                }                       
            }
            
            this.xDown = null;
            this.yDown = null;                                             
        },
        updateScroll() {                        
            this.$emit('height', this.$refs.infoProblem.clientHeight);
        },
    },
    mounted(){
        window.addEventListener('touchstart', this.handleTouchStart, false);        
        window.addEventListener('touchmove', this.handleTouchMove, false);
        window.addEventListener('scroll', this.updateScroll);
    },
    beforeUnmount() {           
        window.removeEventListener('touchstart', this.handleTouchStart);        
        window.removeEventListener('touchmove', this.handleTouchMove);     
        window.removeEventListener('scroll', this.updateScroll);    
    }
}
</script>

<style lang="scss">
#understanding-mobile {
    position: relative;
    display: flex;
    background: var(--linear, linear-gradient(202deg, #1D4A59 0%, #276A80 100%));
    box-shadow: -2px -2px 20px 0px rgba(0, 0, 0, 0.40);
    align-items: center;
    justify-content: center;

    .img-bg{
        width: 506px;
        height: 469px;
        position: absolute;
        top: 10%;
        right: 35%;
        transform: translateX(50%);
    }
    .utp__box-content-mobile {
        display: flex;
        flex-direction: column;
        padding: 60px 20px 50px;
        .utp__wrap-title-mobile {
            display: flex;                
            flex-direction: column;
            margin: auto;   
            width: 100%;
            .utp-title-mobile {
                color:  #8BD4E9;
                text-align: center;
                font-family: Poppins;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
            }            
        }

        .utp__wrap-img-mobile{
            position: relative;            
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: 226px;
            img{
                position: absolute;
                top: 0;
                right: 50%;
                transform: translateX(50%);
                height: auto;
                width: 199px;
                opacity: 0;
                transition: opacity 0.6s ease-in-out;                
            }
            .active{
                opacity: 1;
            }
        }

        .utp__wrap-text-mobile {
            position: relative;            
            display: flex;
            flex-flow: column;
            gap: 10px;           
            min-height: 185px;
            width: 100%;
            
            .utp__box-text-mobile {
                position: absolute;
                top: 0;
                right: 50%;
                transform: translateX(50%);
                opacity: 0;
                max-width: 520px;
                width: 100%;                
                text-align: center;
                font-family: DM Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                gap: 10px;
                transition: opacity 0.6s ease-in-out;
                &.active{
                    opacity: 1;
                }
                
                .utp__text-title-mobile {
                    text-align: center;
                    font-family: Poppins;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                }
            }
        }
        .control-slider{
            width: 100%;
            display: flex;
            gap: 9px;
            margin: 30px auto 0;
            justify-content: center;
            .dot{
                width: 30px;
                height: 5px;
                border-radius: 4px;
                background: rgba(217, 217, 217, 0.20);
                &.active{
                    background: #8BD4E9;
                }
            }
        }
    }
}
</style>