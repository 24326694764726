<template>
    <section id="features-mobile" class="section-container">
        <div class="feat__box-content-mobile">
            <div class="feat__title-mobile">
                Our Solutions
            </div>
            <div class="feat__text-mobile">
                AlgoSafe is a set of smart contracts, APIs and SDKs that provide multi-sig functionality for use on the Algorand blockchain. It allows any Dapp on Algorand to integrate multi-sig functionality inside it, finally unlocking the true power of multi-sig on Algorand.
            </div>
            <div class="feat__mobile-box-content">
                <div class="feat__wrap-content-mobile" v-for="(item, index) in features" :key="index">                    
                    <div class="feat__title-mobile small">
                        {{item.title}}
                    </div>
                    <div class="feat__text-mobile small" v-html="item.paragraph"></div>                    
                </div>
            </div>            
        </div>
    </section>
</template>

<script>
export default {
    name: 'foundy-safe-features',
    data() {
        return {
            features:[
                {                    
                    title: 'Multi-sig Approvals',
                    paragraph:'Fully customize how you manage your crypto assets with the requirement of a pre-defined number of signatures to confirm transactions. This allows partners and institutions to transact safely and transparently, and individuals to create alternative back-ups in the event a wallet is compromised.'
                },
                {                    
                    title: 'All Algorand Dapps',
                    paragraph:`Utilize multi-sig functionality with all Algorand Dapps. You are now free to trade, lend, invest and earn on Algorand, with the security of multi-sig allowances.`
                },
                {                    
                    title: 'All Algo Assets',
                    paragraph:'AlgoSafe supports ALGO, the native token of the Algorand blockchain, as well as all standard ASAs. '
                },
                {
                    title: 'Wallets',
                    paragraph:'Use any Algorand wallet to set up and approve transactions.'
                },                
                {                   
                    title: 'Ledger Accounts',
                    paragraph:`Seamlessly import and manage your Ledger accounts directly within AlgoSafe's interface. You can now perform transactions using your hardware wallet with ease.`
                },         
            ],
        }
    }
}
</script>

<style lang="scss">
#features-mobile {
    display: flex;        
    justify-content: center;
    background: linear-gradient(132deg, #F2FDFF, #BFE2EA);
    background-size: 400% 400%;
    animation: BackgroundGradient 5s ease infinite;
    box-shadow: 0px -2px 10px 2px rgba(255, 255, 255, 0.25);
    padding: 60px 20px 90px;
    height: max-content;
    
    .feat__mobile-box-content{
        display: flex;
        flex-flow: column;
        gap: 20px;
        .feat__wrap-content-mobile{
            border-radius: 20px;
            background: linear-gradient(180deg, #DCF8FF 39.58%, #FFF 100%, #FFF 100%);
            display: flex;
            flex-direction: column;
            padding: 20px;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
        }
    }

    .feat__box-content-mobile {
        display: flex;
        flex-flow: column;
        gap: 30px;
        
        .feat__title-mobile {
            text-align: center;
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;            
            color: #258BAC;
            &.small{
                font-size: 24px;                
                font-weight: 600;
                color: #000000;
            }
            
        }
        .feat__text-mobile {
            color: #000;
            text-align: center;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 160%;
            &.small{
                font-weight: 400;
                font-size: 12px;
                text-align: start;
            }
        }
        
    }
}
</style>