<template>    
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10284_78307)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74408 2.74408C3.06951 2.41864 3.59715 2.41864 3.92259 2.74408L17.2559 16.0774C17.5814 16.4028 17.5814 16.9305 17.2559 17.2559C16.9305 17.5814 16.4028 17.5814 16.0774 17.2559L2.74408 3.92259C2.41864 3.59715 2.41864 3.06951 2.74408 2.74408Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2559 2.74408C17.5814 3.06951 17.5814 3.59715 17.2559 3.92259L3.92259 17.2559C3.59715 17.5814 3.06951 17.5814 2.74408 17.2559C2.41864 16.9305 2.41864 16.4028 2.74408 16.0774L16.0774 2.74408C16.4028 2.41864 16.9305 2.41864 17.2559 2.74408Z" fill="currentColor"/>
    </g>
    <defs>
        <clipPath id="clip0_10284_78307">
            <rect width="20" height="20" fill="white"/>
        </clipPath>
    </defs>
    </svg>

</template>