<template>
    <div class="backdrop-contact"></div>
    <div class="contact-us__container">
        <div class="contact-us__header">
            <div class="text-header">
                Contact Us
            </div>
            <CloseIcon @click="if (!loading)$emit('exit');"/>
        </div>
        <div class="contact-us__wrap-input">
            <div class="form-group small-box">
                <label for="fullNameTextInput">Name <span>*</span></label>
                <input  v-model="formUser.name"  type="text" id="fullNameTextInput" class="form-control text-black" placeholder="Enter your name">            
            </div>
            <div class="form-group w-flex position-relative">
                <label for="fullNameTextInput">Email <span>*</span></label>
                <input  v-model="formUser.email" @input="isValidEmail" type="email" id="fullNameTextInput" class="form-control text-black" placeholder="Enter your email">            
                <span v-if="emailInvalid" class="error-msg">Invalid Email</span>
            </div>
        </div>
        <div class="contact-us__wrap-input">
            <div class="form-group">
                <label for="fullNameTextInput">Type <span>*</span></label>
                <div class="aselect" :data-value="formUser.type" :data-list="list">
                    <div class="selector" @click="toggle()">
                        <div class="label" :class="{'text-black' : formUser.type !== ''}">
                            {{ formUser.type === '' ? 'Select the type of subject' : formUser.type}}
                        </div>
                        <div class="arrow" :class="{ expanded : visible }">
                            <ArrowDown />
                        </div>
                        <div :class="{ hidden : !visible, visible }">
                            <ul>
                                <li :class="{ current : item === formUser.type }" v-for="(item, index) in list" :key="index" @click="select(item)">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <div class="contact-us__wrap-input">
            <div class="form-group position-relative">
                <label for="fullNameTextInput">Algorand Account Address</label>
                <input  v-model="formUser.address" @input="verifyAlgorandAddress" type="text" id="fullNameTextInput" class="form-control text-black" placeholder="Enter Address">            
                <span v-if="addressInvalid" class="error-msg">Invalid Algo Address</span>
            </div>            
        </div>
        <div class="contact-us__wrap-input">
            <div class="form-group">
                <label for="descriptionTextInput">Description of the issue <span>*</span></label>
                <textarea v-model="formUser.description" class="form-control textarea text-black" id="descriptionTextInput" rows="3" placeholder="Describe your issue"></textarea>
            </div>            
        </div>
        <div class="form-check">
            <input
                v-model="formUser.agree"
                type="checkbox"
                checked="checked"
            />
            <label>Share browser and OS versions for better issue resolution</label>
        </div>
        <div class="contact-us__box-btn">
            <button  class="btn btn-primary btn-send" :disabled="loading || emailInvalid || addressInvalid  || formUser.name === '' || formUser.email === '' || formUser.type === '' || formUser.description === ''" @click="submit()">
                {{ loading ? 'LOADING..' : 'SUBMIT' }}
            </button>
        </div>        
    </div>
</template>

<script>
import CloseIcon from './icons/bold/CloseIcon.vue'
import ArrowDown from './icons/ArrowDown.vue'
import axios from "axios";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import algosdk from "algosdk";

export default {
    name: "contact-us",        
    components:{
        CloseIcon,
        ArrowDown
    },
    emits: ["exit"],
    data() {
        return {           
            formUser: {
                name: '',
                email: '',
                type: '',
                address: '',
                description: '',
                agree: false                
            },
            emailInvalid: false, 
            addressInvalid: false, 
			list: ["Multi-sig Safe","Ledger","Others"],
            visible: false,
            loading: false,
        };
    },    
    methods: {
        toggle() {
            this.visible = !this.visible;
        },
        select(option) {
            this.formUser.type = option;
        },
        isValidEmail (){                       
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (emailPattern.test(this.formUser.email)){
                this.emailInvalid = false;
            } else {
                this.emailInvalid = true;
            }
        },
        verifyAlgorandAddress(){            
            try {
                const res = algosdk.decodeAddress(this.formUser.address);
                if (res){
                    this.addressInvalid = false;
                } else {
                    this.addressInvalid = true;
                }
            } catch (err) {
                this.addressInvalid = true;
            }

            if (this.formUser.address.length === 0) this.addressInvalid = false;
        },
        async submit(){
            this.loading = true;
            try {                
                const res = await axios.post( 'https://app.algosafe.io/api/contact-us', this.formUser);
                
                if (res.request.status == 200) {		                    
                    toast("Submitted", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "light",
                        type: "info",
                        toastId: 1,
                    });
                    this.formUser = {
                        name: '',
                        email: '',
                        type: '',
                        address: '',
                        description: '',
                        agree: false                
                    }
                }               
                this.loading = false;
            } catch (e) {
                toast("Fail to submit", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    type: "warning",
                    toastId: 2,
                });
                this.loading = false;                
            }
        }
    },      
}
</script>

<style lang="scss">
.backdrop-contact{
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background: rgba(246, 249, 250, 0.85);
   backdrop-filter: blur(2px);
   z-index: 10;
}
.contact-us__container{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    z-index: 11;
    display: flex;
    width: 500px;
    min-height: 400px;
    padding: 20px;
    gap: 16px;
    flex-direction: column;    
    border-radius: 12px;
    background:  #FFF;
    box-shadow: 0px 2px 4px 0px rgba(92, 92, 92, 0.25);
    font-family: DM Sans;
    color: #000000;
    @media only screen and (min-width: 1200px) {
        width: $px-500;
        min-height: $px-400;
        padding: $px-20;
        gap: $px-16;
        flex-direction: column;    
        border-radius: $px-12;
        background:  #FFF;
        box-shadow: 0px $px-2 $px-4 0px rgba(92, 92, 92, 0.25);
    }
    @media only screen and (max-width: 1199px) {
        top: unset;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);            
        width: 100%;
        border-radius: 20px 20px 0px 0px;
    }
    .contact-us__box-btn{
        width: 100%;
        padding-top: 16px;
        border-top: 1px solid #8BD4E9;
        display: flex;
        justify-content: center;
        @media only screen and (min-width: 1200px) {
            padding-top: $px-16;
            border-top: $px-1 solid #8BD4E9;
        }
        .btn-send{            
            min-width: 107px;
            height: 40px;
            border-radius: 8px;
            padding: 10px;
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            color: #FFFFFF;
            background: #258BAC;
            text-transform: uppercase;        
            box-shadow: none;
            outline: none;
            border: none;
            display: flex;
            gap: 8px;
            justify-content: center;
            &:active, &:focus{
                box-shadow: none;
                outline: none;
                border: none;
                background: #258BAC;
            } 
            &:disabled{            
                border: 1px solid #B3B3B3;
                color: #B3B3B3;
                background: #ffffff;
            }   
            &:hover{
                background: #258BAC;
            }
            @media only screen and (min-width: 1200px) {
                min-width: $px-107;
                height: $px-40;
                border-radius: $px-8;
                padding: $px-10;
                font-size: $px-14;
                gap: $px-8;
                &:disabled{            
                    border: $px-1 solid #B3B3B3;
                }  
            }
        }
    }
    .error-msg{
        position: absolute;
        top: 100%;
        left: 0;
        color: red;
        font-size: 10px;
        @media only screen and (min-width: 1200px) {
            font-size: $px-10;
        }
    }
    input {
        &[type="checkbox"] {
            background-color: #FFF;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            vertical-align: middle;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            border: 1px solid #757575;
            cursor: pointer;
            box-shadow: none;
            @media only screen and (min-width: 1200px) {
                width: $px-20;
                height: $px-20;
                border-radius: $px-4;
                border: $px-1 solid #757575;
            }

            &:checked {
                accent-color: #2574AF;
                appearance: checkbox;
                -webkit-appearance: checkbox;
            }
        }
    }

    .form-check{
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0;
        label{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            @media only screen and (max-width: 1199px) {
                font-size: 12px;
            }
        }
        
        @media only screen and (min-width: 1200px) {
            gap: $px-8;        
            label{
                font-size: $px-14;
            }
        }
    }
}
.contact-us__header{
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #8BD4E9;
    color: #000000;
    .text-header{
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        flex-grow: 1;
        text-align: center;
    }
    svg{
        width: 15px;
        height: 15px;
    }
    @media only screen and (max-width: 1199px) {
        padding-bottom: 10px;
        .text-header{         
            font-size: 14px;
            text-align: left;
        }
        svg{
            width: 11px;
            height: 11px;
        }
    }
    @media only screen and (min-width: 1200px) {
        padding-bottom: $px-18;
        border-bottom: $px-1 solid #8BD4E9;        
        .text-header{
            font-size: $px-18;
        }
        svg{
            width: $px-15;
            height: $px-15;
        }
    }
}
.contact-us__wrap-input{
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    .small-box{
        width: 175px;
    }
    .w-flex{
        flex-grow: 1;
        width: unset;
    }
    @media only screen and (max-width: 1199px) {
        gap: 10px;
        flex-direction: column;
        .small-box, .w-flex{
            width: 100%;
        }
    }
    @media only screen and (min-width: 1200px) {
        gap: $px-16;        
        .small-box{
            width: $px-175;
        }   
    }
}
.form-group{    
    width: 100%;     
    text-align: left;
	position: relative;           
    label{
        font-size: 14px;
        margin-bottom: 6px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        @media only screen and (max-width: 1199px) {
            font-size: 12px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: $px-14; 
            margin-bottom: $px-6;           
        }
        span{
            color: #258BAC;
        }
    }    
    .form-control{
        background: #E6F7FB;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        height: 40px;
        width: 100%;
        padding: 10px 15px;
        outline: none !important;
        box-shadow: none !important;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: #B3B3B3;        
        &.textarea{
            height: 86px;     
            padding: 10px 14px;       
        }
        &:focus, &:active{
            background: #E6F7FB;
            color: #B3B3B3; 
        }
        @media only screen and (max-width: 1199px) {
            font-size: 12px;
        }
        @media only screen and (min-width: 1200px) {
            border-radius: $px-8;
            height: $px-40;
            padding: $px-10 $px-15;
            font-size: $px-14;      
            &.textarea{
                min-height: $px-86;     
                padding: $px-10 $px-14;       
            }
        }
    }                      
}

.aselect {    
    .selector {
        height: 40px;
        padding: 10px 15px;
        border-radius: 8px;
        position: relative;
        background: #E6F7FB;
        z-index: 1;
        @media only screen and (min-width: 1200px) {
            height: $px-40;
            padding: $px-10 $px-15;
            border-radius: $px-8;
        }
        .arrow {
            position: absolute;
            right: 10px;
            top: 16%;            
            transform: rotate(0deg) translateY(0px);
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(.59,1.39,.37,1.01);
            color: #000;
            svg{
                height: 20px;
                width: 20px;
            }
            @media only screen and (min-width: 1200px) {
                right: $px-10;
                svg{
                    height: $px-20;
                    width: $px-20;
                }
            }
        }
        .expanded {
            transform: rotate(180deg) translateY(2px);
            @media only screen and (min-width: 1200px) {
                transform: rotate(180deg) translateY($px-2);
            }
        }
        .label {
            display: block;            
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            color: #B3B3B3;
            @media only screen and (max-width: 1199px) {
                font-size: 12px;
            }
            @media only screen and (min-width: 1200px) {
                font-size: $px-14;
            }
        }
    }
    ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;        
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;       
        position: absolute;
        left: 0;
        top: 107%;
        z-index: 1;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 0px 8px 0px rgba(92, 92, 92, 0.30);
        @media only screen and (max-width: 1199px) {
            font-size: 12px;
        }
        @media only screen and (min-width: 1200px) {
            font-size: $px-14;
            border-radius: $px-8;
            box-shadow: 0 0 $px-8 0 rgba(92, 92, 92, 0.30);
        }
    }
    li {
        padding: 12px 14px;
        color: #000;
        @media only screen and (min-width: 1200px) {
            padding: $px-12 $px-14;
        }
        &:hover {
            background: #E6F7FB;
        }
    }
    .current {
        background: #E6F7FB;
    }
    .hidden {
        visibility: hidden;
    }
    .visible {
        visibility: visible;
    }
}
</style>