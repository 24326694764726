<template>
    <div class="landing-wrapper">
        <foundry-logo :landingHeight="heightLanding" :problemHeight="heightProblem"/>

        <FoundryLandingSection @height="updatedLanding"/>

        <FoundryUnderstandingSection @height="updateProblem"/>

        <FoundryFeatureSection />
    </div>
</template>

<script>
import FoundryLogo from './FoundrySafe/FoundryLogo.vue'
import FoundryLandingSection from './FoundrySafe/FoundryLandingSection.vue'
import FoundryFeatureSection from './FoundrySafe/FoundryFeatureSection.vue'
import FoundryUnderstandingSection from './FoundrySafe/FoundryUnderstandingSection.vue'
export default {
    name: "foundry-main-landing",
    components: {
        FoundryLogo,
        FoundryLandingSection,
        FoundryFeatureSection,
        FoundryUnderstandingSection
    },
    data() {
        return {            
            heightLanding: 0,
            heightProblem: 0,
        };
    },
    methods:{
        updatedLanding(height){
            this.heightLanding = height;            
        },
        updateProblem(h){
            this.heightProblem = h;
        }

    }
}
</script>

<style lang="scss">
.landing-wrapper {
    max-width: 100vw;
    // background-color: var(--bg-color-primary);
    overflow-x: hidden;    

    .section-container {
        width: 100vw;
        height: 100vh; 
        position: relative;
        overflow-x: hidden;     
    }
}
</style>
