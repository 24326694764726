<template>
    <div class="cont-head-mobile-safe">  
        <div class="fs-mobile-logo logo-wrap" :class="{dark: blackImg}" @click="navSlide">
            <IconMenu />
            <img 
                :src="blackImg ? '/img/foundry-safe/AlgoSafeWhite.svg' : '/img/foundry-safe/Algosafe.svg'" 
                class="logo-safe"                 
                alt="fs-logo" 
            />
        </div>
        <div class="d-flex align-items-center gap-1">
            <button  class="btn btn-primary btn-mobile-fs" @click="goExLink()">
                <span>LAUNCH APP</span>
                <IconAppMenuVue />
            </button>
            <div class="position-relative">
                <button  class="btn btn-primary btn-media" @click="visibleMedia = !visibleMedia">                    
                    <IconMenu v-if="!visibleMedia" />
                    <CloseIcon  v-else/>
                </button>
                
                <div class="box-media" v-if="visibleMedia">
                    <a class="media-text w-100" href="https://docs.algofoundry.studio/our-products/foundry-safe" target="_blank" @click="visibleMedia = !visibleMedia">
                        <BookIcon />
                        <span>DOCS</span>
                    </a>
                    <div role="button" class="media-text w-100" @click="contact = true; visibleMedia = !visibleMedia;">                                
                        <Mail />
                        <span>CONTACT US</span>
                    </div>
                    <div class="line-media" />
                    <a class="media-text w-100" href="https://discord.com/invite/9EqPmrRA29" target="_blank" @click="visibleMedia = !visibleMedia">
                        <DiscordIcon />
                        <span>DISCORD</span>
                    </a>
                    <a class="media-text w-100" href="https://www.linkedin.com/company/algo-foundry" target="_blank" @click="visibleMedia = !visibleMedia">
                        <LinkedinIcon />
                        <span>LINKEDIN</span>
                    </a>
                    <a class="media-text w-100" href="https://algofoundry.medium.com/" target="_blank" @click="visibleMedia = !visibleMedia">
                        <MediumIcon />
                        <span>MEDIUM</span>
                    </a>
                    <a class="media-text w-100" href="https://www.reddit.com/r/AlgoFoundry_/" target="_blank" @click="visibleMedia = !visibleMedia">
                        <RedditIcon />
                        <span>REDDIT</span>
                    </a>
                    <a class="media-text w-100" href="https://twitter.com/algo_foundry" target="_blank" @click="visibleMedia = !visibleMedia">
                        <TwitterIcon />
                        <span>TWITTER</span>
                    </a>                        
                </div>
            </div>
        </div>        
        <div class="nav-items">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="wrapper">
                    <div class="brand-img-items" @click="navSlide">
                        <ArrowLeft />
                        <img 
                            src="/img/foundry-safe/Algosafe.svg" 
                            class="logo-safe"                 
                            alt="fs-logo" 
                        />
                    </div >
                    <div class="wrapper-content">                        
                        <div class="box-link-text">
                            <a href="https://docs.algofoundry.studio/our-products/foundry-safe" class="terms-text" target="_blank">
                                DOCS
                            </a> 
                            <a href="/file/algo_foundry_privacy.pdf" class="terms-text" target="_blank">
                                PRIVACY POLICY
                            </a>                        
                            <a href="/file/algo_foundry_terms.pdf" class="terms-text" target="_blank">
                                TERMS
                            </a>
                        </div>                        
                    </div>
                    <div class="footer-text">
                        <div class="box-icon-media">
                            <a href="https://discord.com/invite/9EqPmrRA29" target="_blank">
                                <img src="/img/foundry-safe/discord.svg"  alt="">
                            </a>
                            <a href="https://www.linkedin.com/company/algo-foundry" target="_blank">
                                <img src="/img/foundry-safe/linkedin-logo.svg" alt="lindked-logo" />
                            </a>
                            <a href="https://algofoundry.medium.com/" target="_blank">
                                <img src="/img/foundry-safe/medium.svg"  alt="">
                            </a>                                                        
                            <a href="https://www.reddit.com/r/AlgoFoundry_/" target="_blank">
                                <img src="/img/foundry-safe/reddit.svg"  alt="">
                            </a>                            
                            <a href="https://twitter.com/algo_foundry" target="_blank">
                                <img src="/img/foundry-safe/twitter-logo.svg" alt="twitter-logo" />
                            </a>
                        </div>
                        <span>©{{yearNow}} ALGOFOUNDRY.STUDIO</span>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ContactUs v-if="contact" @exit="contact = false"/>
</template>

<script>
import IconAppMenuVue from '../../icons/IconAppMenu.vue';
import IconMenu from '../../icons/IconMenuBurger.vue'
import ArrowLeft from '../../icons/ArrowLeft.vue'
import ContactUs from '../../ContactUs.vue'

import CloseIcon from '../../icons/light/CloseIcon.vue';
import RedditIcon from '../../icons/bold/RedditIcon.vue'
import DiscordIcon from '../../icons/bold/DiscordIcon.vue'
import MediumIcon from '../../icons/bold/MediumIcon.vue'
import LinkedinIcon from '../../icons/bold/LinkedinIcon.vue'
import TwitterIcon from '../../icons/bold/TwitterIcon.vue'
import BookIcon from '../../icons/bold/BookIcon.vue'
import Mail from '../../icons/MailPush.vue'

export default {
    name: "foundry-main-mobile",
    props: {        
        landingHeight: {
            type: Number,
            default: 0,
        },
        problemHeight: {
            type: Number,
            default: 0,
        },
    },
    components:{
        IconMenu,
        IconAppMenuVue,
        ArrowLeft,
        ContactUs,
        CloseIcon,
        RedditIcon,
        DiscordIcon,
        MediumIcon,
        LinkedinIcon,
        TwitterIcon,
        BookIcon,
        Mail
    },    
    data() {
        return {
            yearNow: new Date().getFullYear(),
            openNav: false,
            scrollPosition: 0,
            blackImg: false,
            landingHg: 0,
            problemHg: 0,
            visibleMedia: false,
            contact: false
        };
    },
    watch: {
        openNav(newVal){
            if(newVal){
                document.querySelector('body').style.overflow = 'hidden';
            } else {
                document.querySelector('body').style.overflow = 'auto';                
            }
        },
        landingHeight(newVal){
            this.landingHg = newVal;
        },
        problemHeight(newVal){
            this.problemHg = newVal;
        },
    },
    methods: {
        navSlide() {
            this.openNav = !this.openNav;
            const fsLogo = document.querySelector('.fs-mobile-logo')
            const nav = document.querySelector('.nav-items')

            nav.classList.toggle('nav-active')
            fsLogo.classList.toggle('fs-close')
        },
        goExLink(){                          
            window.open('https://app.algosafe.io', '_blank');
        },
        updateScroll() {
            this.scrollPosition = window.scrollY;         
            
            if (this.scrollPosition > (this.landingHg - 30) && this.scrollPosition < (this.problemHg + this.landingHg)){
                this.blackImg = true;
            } else {
                this.blackImg = false;
            }
        },
    },
    mounted(){                
        window.addEventListener('scroll', this.updateScroll);
    },
    beforeUnmount() { 
        window.removeEventListener('scroll', this.updateScroll);       
    },   
}
</script>

<style lang="scss">
.cont-head-mobile-safe{
    width: 100%;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(10px);
    height: 50px;
    position: fixed;
    top: 0;
    z-index: 10;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
}
.fs-mobile-logo {       
    cursor:pointer;    
}
.logo-wrap{
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #258BAC;
    &.dark{
        color: #FFFFFF;
    }
    .logo-safe{
        width: 96px;
        height: 19px;
    }
    svg{
        width: 20px;
        height: 20px;
    }
}
.btn-mobile-fs, .btn-media {          
    width: 116px;
    height: 34px;
    border-radius: 8px !important;
    background: #258BAC !important;
    color: #FFF !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 150% !important;
    text-transform: uppercase;        
    box-shadow: none;
    outline: none;
    border: none !important;
    display: flex !important;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    &:active, &:focus {
        box-shadow: none;
        outline: none;
        border: none;
    }
    &:hover, &:active, &:focus{
        background: #258BAC !important;
        color: #FFF;
    }
    svg{
        width: 20px;
        height: 20px;
    }
}

.btn-media{
    width: 34px;
    border-radius: 8px !important;
    background: #8BD4E9 !important;
    color: #000 !important;
    &:hover{
        background: #8BD4E9 !important;
        color: #000 !important;
    }    
}

// Navbar
.navbar-collapse {
    flex: 0;

    &.collapse:not(.show) {
        display: block;
    }
}

.nav-items {    
    width: 238px; //calc(100% - 130px);
    margin: 0;
    background: #FFF;
    box-shadow: -2px -2px 10px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: flex-start;    
    flex-direction: column;
    position: fixed;
    left: 0;
    height: 100vh;
    top: 0;
    transform: translateX(calc(-100% - 130px));
    transition: transform 0.4s ease-in;

    &.nav-active {        
        transform: translateX(0%) !important;
        z-index: 99;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 0 10px;
    }
    .footer-text{
        margin: auto auto 70px;                 
        font-family: DM Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        color: #B3B3B3;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .brand-img-items {
        display: flex;
        gap: 10px;
        align-items: center;
        color: #258BAC;
        height: 50px;
        svg{
            width: 18px;
            height: 18px;
            transform: rotate(180deg);
        }
        img {
            width: 96px;
            height: 19px;
        }
    }
    .box-icon-media{
        display: flex;
        align-items: center;
        gap: 17px;
        img{
            width: 26px;
            height: 26px;
        }
    }
    .wrapper-content {
        display: flex;
        padding-left: 6px;
        margin-top: 32px;
        flex-direction: column;        
        .box-link-text{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        a{
            color: #000;
            font-family: DM Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            text-decoration: none;
        }
    }
}
.box-media{
    position: absolute; 
    right: 0; 
    top: 120%;
    display: flex;
    width: 200px;        
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #FFF;
    color: #000;
    box-shadow: 0px 2px 4px 0px rgba(92, 92, 92, 0.25);
    font-family: DM Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 160%;            
    .media-text{
        font-weight: 400;        
        display: flex;
        gap: 10px;
        height: 50px;
        align-items: center;    
        padding: 0px 14px;
        &.title{
            color: #B3B3B3;            
            font-weight: 700;        
            border-top: 1px solid #B3B3B3;
        }
    }
    .line-media{
        width: 100%;
        border-bottom: 1px solid #B8E9F4;
    }
    a{
        text-decoration: none;
        color: #000;
        cursor: pointer;
    }        
    svg{
        width: 20px;
        height: 20px;
    }
}
</style>