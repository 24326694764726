<template>
    <section id="landing-safe-mobile" class="section-container" ref="infoLanding">
        <div class="ls__box-mobile-content">
            <div class="ls__content-mobile-wrap">
                <video class="fs-safe-mobile" autoplay muted loop preload="auto" playsinline>
                    <source src="/img/foundry-safe/safelock_foundrysafe_1072-hevc-safari-hevc-safari.mp4" type='video/mp4; codecs="hvc1"' />
                    <source src="/img/foundry-safe/safelock_foundrysafe_1072-hevc-safari-hevc-safari.mov" type='video/mp4; codecs="hvc1"' />
                    <source src="/img/foundry-safe/safelock_foundrysafe_1072-vp9-chrome.webm" type="video/webm" />
                </video>
                <div class="ls__box-mobile-text">
                    <div class="ls__title-mobile">
                        <span>Your Trusted Multi-Sig to Manage Digital Assets on </span>
                        <IconAlgorand />
                    </div>     
                    <div class="ls__wrap-icon">
                        <div class="ls__wrap-icon wrap-small">
                            <div class="icon-title">
                                Grant Recipient 
                            </div>
                            <a href="https://www.algorand.foundation/" target="_blank">
                                <img                                    
                                    src="/img/foundry-safe/algorand-foundation-logo_black.svg"
                                    alt="Algorand Foundation"                                            
                                />
                            </a>
                        </div>
                        <div class="ls__wrap-icon wrap-small">
                            <div class="icon-title">
                                Audited by
                            </div>
                            <a href="https://www.shellboxes.com/" target="_blank">
                                <img                                    
                                    src="/img/foundry-safe/shellboxes_black.svg"
                                    alt="ShellBoxes"                                            
                                />
                            </a>
                        </div>
                    </div>               
                </div>                
            </div>
        </div>
    </section>
</template>

<script>
import IconAlgorand from '../../icons/IconAlgorand.vue'

export default {
    components:{
        IconAlgorand
    },
    name: 'foundy-safe-landing',
    data() {
        return {            
            heightDiv: 0,
        };
    },
    methods:{
        updateScroll() {                        
            this.$emit('height', this.$refs.infoLanding.clientHeight);
        },
    }, 
    mounted(){                
        window.addEventListener('scroll', this.updateScroll);
    },
    beforeUnmount() { 
        window.removeEventListener('scroll', this.updateScroll);       
    },   
}
</script>

<style lang="scss">
#landing-safe-mobile {
    display: flex;
    flex-direction: column;
    height: max-content;

    .ls__box-mobile-content {
        width: 100%;
        height: max-content;
        background: linear-gradient(132deg, #F2FDFF, #BFE2EA);
        background-size: 400% 400%;
        animation: BackgroundGradient 5s ease infinite;
        box-shadow: 0px -2px 10px 2px rgba(255, 255, 255, 0.25);
        padding-top: 50px;
        
        
        .ls__content-mobile-wrap {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .ls__box-mobile-text {
                display: flex;
                flex-flow: column;  
                gap: 30px;
                margin-bottom: 60px;
                padding: 0 20px;

                .ls__title-mobile{
                    text-align: center;
                    font-family: Poppins;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    background: var(--linear, linear-gradient(202deg, #1D4A59 0%, #276A80 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: inline-block;
                    svg {
                        display: inline-block;
                        vertical-align: middle;
                        width: 157px;
                        height: auto;
                    }
                }     
                .ls__wrap-icon{
                    display: flex;
                    gap: 30px;
                    align-items: center;
                    justify-content: center;
                    &.wrap-small{
                        gap: 10px;
                        flex-direction: column;
                    }
                    .icon-title{
                        color: #414B5A;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%; 
                    }
                    img{
                        height: 41px;
                        width: auto;
                    }
                }         
            }
            
            .fs-safe-mobile {                    
                width: 318px;//275px;
                margin: 30px auto;
                
            }
        }
    }
}
</style>