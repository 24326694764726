<template>
  <div class="home">
    <div v-if="isXlAndUp">
        <FoundrySafeDesktop />
    </div>
    <div v-else>
        <FoundrySafeMobile />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FoundrySafeDesktop from '../components/FoundrySafeDesktop.vue'
import FoundrySafeMobile from '../components/mobile/MobileLandingFoundrySafe.vue'

/* eslint-disable */
export default {
  name: 'Home',
  components: {    
    FoundrySafeDesktop,
    FoundrySafeMobile
  },
  watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = 'AlgoSafe';
            }
        },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      br: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    };
  },

  computed: {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2000}, (value, index) => 2001 + index).reverse();
    },
    isXlAndUp() {
      return this.br.xl <= this.windowWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
  }
}
</script>
