<template>    
    <div class="foundry-safe__container" :class="{maxTop: windowHeight > 720}">        
        <div class="header-safe" :class="{darker: blackImg}">
            <img :src="blackImg ? '/img/foundry-safe/AlgoSafeWhite.svg' : '/img/foundry-safe/Algosafe.svg'" class="fs-logo" @click="$router.push({path:'/'})" alt="">
            <div class="d-flex align-items-center gap-2">
                <button  class="btn btn-primary btn-fs" @click="goExLink()">
                    LAUNCH APP 
                    <IconAppMenuVue />
                </button>                
                <div class="position-relative">
                    <button  class="btn btn-primary btn-fs btn-media" @click="visibleMedia = !visibleMedia">                    
                        <IconMenuBurger v-if="!visibleMedia" />
                        <CloseIcon  v-else/>
                    </button>
                    
                    <div class="box-media" v-if="visibleMedia">
                        <a class="media-text w-100" href="https://docs.algofoundry.studio/our-products/foundry-safe" target="_blank" @click="visibleMedia = !visibleMedia">
                            <BookIcon />
                            <span>DOCS</span>
                        </a>
                        <div role="button" class="media-text w-100" @click="contact = true; visibleMedia = !visibleMedia;">                                
                            <Mail />
                            <span>CONTACT US</span>
                        </div>
                        <div class="line-media" />
                        <a class="media-text w-100" href="https://discord.com/invite/9EqPmrRA29" target="_blank" @click="visibleMedia = !visibleMedia">
                            <DiscordIcon />
                            <span>DISCORD</span>
                        </a>
                        <a class="media-text w-100" href="https://www.linkedin.com/company/algo-foundry" target="_blank" @click="visibleMedia = !visibleMedia">
                            <LinkedinIcon />
                            <span>LINKEDIN</span>
                        </a>
                        <a class="media-text w-100" href="https://algofoundry.medium.com/" target="_blank" @click="visibleMedia = !visibleMedia">
                            <MediumIcon />
                            <span>MEDIUM</span>
                        </a>
                        <a class="media-text w-100" href="https://www.reddit.com/r/AlgoFoundry_/" target="_blank" @click="visibleMedia = !visibleMedia">
                            <RedditIcon />
                            <span>REDDIT</span>
                        </a>
                        <a class="media-text w-100" href="https://twitter.com/algo_foundry" target="_blank" @click="visibleMedia = !visibleMedia">
                            <TwitterIcon />
                            <span>TWITTER</span>
                        </a>                        
                    </div>
                </div>
                
            </div>
        </div>
        <section id="landing-safe" class="section-container">            
            <div class="ls__box-content" ref="contentPage">
                <div class="ls__content-wrap">
                    <video class="fs-safe" preload="auto" autoplay muted loop playsinline>
                        <source src="/img/foundry-safe/safelock_foundrysafe_1072-hevc-safari-hevc-safari.mp4" type='video/mp4; codecs="hvc1"' />
                        <source src="/img/foundry-safe/safelock_foundrysafe_1072-hevc-safari-hevc-safari.mov" type='video/mp4; codecs="hvc1"' />
                        <source src="/img/foundry-safe/safelock_foundrysafe_1072-vp9-chrome.webm" type="video/webm" />                        
                    </video>
                    <div class="ls__box-container flex-column">
                        <div class="ls__box-text">
                            Your Trusted Multi-Sig to Manage Digital Asset on <IconAlgorand />
                        </div>                                        
                        <div class="ls__wrap-icon">
                            <div class="ls__wrap-icon wrap-small">
                                <div class="icon-title">
                                    Grant Recipient 
                                </div>
                                <a href="https://www.algorand.foundation/" target="_blank">
                                    <img                                    
                                        src="/img/foundry-safe/algorand-foundation-logo_black.svg"
                                        alt="Algorand Foundation"                                            
                                    />
                                </a>
                            </div>
                            <div class="ls__wrap-icon wrap-small">
                                <div class="icon-title">
                                    Audited by
                                </div>
                                <a href="https://www.shellboxes.com/" target="_blank">                                
                                    <img                                    
                                        src="/img/foundry-safe/shellboxes_black.svg"
                                        alt="ShellBoxes"                                            
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>            
        </section>
        <section id="understanding" class="section-container">
            <div class="utp__box-content">
                <div class="utp__wrap-title">                    
                    We understand the problems                    
                </div>
                <div class="utp__wrap-text">
                    <div 
                        class="utp__box-text" 
                        v-for="(item, index) in utp" 
                        :key="index" 
                        :class="{active: index === contentPage2}"
                        @click="selectUtp(index)"
                    >
                        <div class="utp__text-title">
                            {{item.title}}
                        </div>
                        <span>
                            {{item.subtitle}}
                        </span>                        
                    </div>
                </div>
            </div>
            <div class="utp__box-img">
                <img
                    class="img-utp img-bg"
                    src="/img/foundry-safe/bg-blue-star.svg"
                    alt=""                                            
                />
                <img
                    class="img-utp img-1 img-transition"
                    src="/img/foundry-safe/algo-star.png"
                    alt=""
                    :class="{show: contentPage2 === 0}"
                />
                <img
                    class="img-utp img-2 img-transition"
                    src="/img/foundry-safe/wallet-big.png"
                    alt=""                             
                    :class="{show: contentPage2 === 1}"               
                />
                <img
                    class="img-utp img-3 img-transition"
                    src="/img/foundry-safe/ledger.png"
                    alt=""                           
                    :class="{show: contentPage2 === 2}"                 
                />
            </div>
        </section>
        <section id="features" class="section-container">
            <div class="feat__wrap-cont d-flex w-100 justify-content-center" >
                <div class="feat__box-container">
                    <div class="feat__box-big">
                        <div class="feat__title big">
                            Our Solutions
                        </div>
                        <div class="feat__text">
                            AlgoSafe is a set of smart contracts, APIs and SDKs that provide multi-sig functionality for use on the Algorand blockchain. It allows any Dapp on Algorand to integrate multi-sig functionality inside it, finally unlocking the true power of multi-sig on Algorand.
                        </div>
                    </div>
                    <div class="feat__box-small" :class="{in: section3}">
                        <div class="feat__content-wrap" v-for="(feat, index) in features" :key="index" >
                            <div class="feat__title">
                                {{feat.title}}
                            </div>
                            <div class="feat__text small" :class="feat.class" v-html="feat.paragraph">                                
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            <div class="fs__footer">
                <div class="footer-box">
                    <div class="d-flex align-items-center gap-2">
                        <div class="footer-display">
                            <a href="https://algofoundry.studio" target="_blank">ALGOSAFE</a>
                            <span>©{{yearNow}} </span>                                            
                        </div>
                        <div class="footer-display align-items-center">
                            <span>Brought to you by</span>
                            <img                                
                                src="/img/foundry-safe/algo-foundry-horizontal-logo.png"
                                alt=""               
                            />
                        </div>
                    </div>                    
                    <div class="footer-box-icon">
                        <a href="mailto:hello@algofoundry.studio" class="footer-text-link">
                            hello@algofoundry.studio
                        </a>
                        <div class="footer-space-line">|</div>
                        <a href="https://docs.algofoundry.studio/our-products/foundry-safe" class="footer-text-link" target="_blank">
                            DOCS
                        </a>
                        <div class="footer-space-line">|</div>
                        <a href="/file/algo_foundry_privacy.pdf" class="footer-text-link" target="_blank">
                            PRIVACY POLICY
                        </a>
                        <div class="footer-space-line">|</div>
                        <a href="/file/algo_foundry_terms.pdf" class="footer-text-link" target="_blank">
                            TERMS
                        </a>
                        <div class="footer-link">
                            <a href=" https://discord.com/invite/9EqPmrRA29" target="_blank">
                                <img src="/img/foundry-safe/discord.svg"  alt="">
                            </a>
                            <a href="https://www.linkedin.com/company/algo-foundry" target="_blank">
                                <img src="/img/foundry-safe/linkedin.svg"  alt="">
                            </a>
                            <a href="https://algofoundry.medium.com/" target="_blank">
                                <img src="/img/foundry-safe/medium.svg"  alt="">
                            </a>                        
                            <a href="https://www.reddit.com/r/AlgoFoundry_/" target="_blank">
                                <img src="/img/foundry-safe/reddit.svg"  alt="">
                            </a>
                            <a href="https://twitter.com/algo_foundry" target="_blank">
                                <img src="/img/foundry-safe/twitter.svg"  alt="">
                            </a>                                                                                    
                        </div>
                    </div>
                </div>
            </div>  
        </section>          
        <ContactUs v-if="contact" @exit="contact = false;"/>       
        
    </div>        
</template>

<script>
import IconAppMenuVue from './icons/IconAppMenu.vue';
import IconAlgorand from './icons/IconAlgorand.vue';
import IconMenuBurger from './icons/IconMenuBurger.vue';
import CloseIcon from './icons/light/CloseIcon.vue';
import RedditIcon from './icons/bold/RedditIcon.vue'
import DiscordIcon from './icons/bold/DiscordIcon.vue'
import MediumIcon from './icons/bold/MediumIcon.vue'
import LinkedinIcon from './icons/bold/LinkedinIcon.vue'
import TwitterIcon from './icons/bold/TwitterIcon.vue'
import BookIcon from './icons/bold/BookIcon.vue'
import Mail from './icons/MailPush.vue'
import ContactUs from './ContactUs.vue'


export default {
    components:{
        IconAppMenuVue,
        IconAlgorand,
        IconMenuBurger,
        CloseIcon,
        TwitterIcon,
        LinkedinIcon,
        RedditIcon,
        MediumIcon,
        DiscordIcon,
        BookIcon,
        Mail,
        ContactUs
    },    
    data() {        
        return {    
            contact: false,
            visibleMedia: false,
            contentPage2: 0,
            interval: null,
            page1: false,
            vhClient: null,
            windowHeight: null,
            blackImg: false,
            section2: false,
            section3: false,
            scrollPosition: 0,
            utp:[
                {
                    title: 'Algorand Atomics',
                    subtitle:'Algorand network design and constraints leads to an architecture that relies heavily on atomic transactions. This reliance makes it difficult to use the built-in multi-sig capability of Algorand with DeFi projects.'
                },
                {
                    title: 'Hot Wallet & Key-Person Risk',
                    subtitle:'Hot wallets are prone to hacks and once compromised there are few options for recovery. Single signatures also introduce ‘key-person’ risk where everything is controlled and could be compromised by a single wallet.'                    
                },
                {
                    title: 'Ledger Lacking',
                    subtitle:'Hardware wallets like Ledger have few options for user interfaces that interact with the Algorand blockchain. MyAlgo was the most popular until it was compromised, and others are not deeply integrated with Dapps leading to a fractured ecosystem.'
                }
            ],
            features:[
                {                    
                    title: 'Multi-sig Approvals',
                    paragraph:'Fully customize how you manage your crypto assets with the requirement of a pre-defined number of signatures to confirm transactions. This allows partners and institutions to transact safely and transparently, and individuals to create alternative back-ups in the event a wallet is compromised.'
                },
                {                    
                    title: 'All Algorand Dapps',
                    paragraph:`Utilize multi-sig functionality with all Algorand Dapps. You are now free to trade, lend, invest and earn on Algorand, with the security of multi-sig allowances.`,
                    class: 'p-bottom'
                },
                {                    
                    title: 'All Algo Assets',
                    paragraph:'AlgoSafe supports ALGO, the native token of the Algorand blockchain, as well as all standard ASAs. '
                },
                {
                    title: 'Wallets',
                    paragraph:'Use any Algorand wallet to set up and approve transactions.'
                },                
                {                   
                    title: 'Ledger Accounts',
                    paragraph:`Seamlessly import and manage your Ledger accounts directly within AlgoSafe's interface. You can now perform transactions using your hardware wallet with ease.`
                },                
            ],
            yearNow: new Date().getFullYear()
        };
    },
    watch:{
       windowHeight(newVal) {
            this.vhClient = newVal;
        },
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY;
            const clientH = this.vhClient > 720 ? 720 : this.vhClient;
            const aspectRatio = window.innerWidth/window.innerHeight;
            if (this.scrollPosition > (clientH * 0.2)){
                this.section2 = true;
            }
            if (this.scrollPosition > (aspectRatio > 1.23 ? (clientH * 1.15) : (aspectRatio < 1.1 ? (clientH * 0) : (clientH * 1)))){
                this.section3 = true;
            }
            if (this.scrollPosition > (window.innerWidth <= 1440  ? (clientH * 0.9): (this.vhClient * 0.85)) && this.scrollPosition < ((window.innerWidth <= 1440 ? clientH: this.vhClient) * 2 )){
                this.blackImg = true;
            } else {
                this.blackImg = false;
            }
            this.$refs.contentPage.style.top = `calc(52% - ${window.scrollY}px)`;

        },       
        onResize() {
            this.windowHeight = window.innerHeight;            
        },
        goExLink(){             
            window.open('https://app.algosafe.io', '_blank');            
        },
        selectUtp(index){
            this.contentPage2 = index;
            clearInterval(this.interval);
        }
    },
    created(){
        this.windowHeight = window.innerHeight;         
    },
    mounted(){         
        this.$nextTick(function () {            

            window.addEventListener('resize', this.onResize);
        });
        this.vhClient = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        window.addEventListener('scroll', this.updateScroll);

        this.interval = setInterval(() => {             
            if(this.section2 === true){                
                this.contentPage2++;            
                if (this.contentPage2 > 2){
                    this.contentPage2 = 0;
                }
            }                              
        }, 3500);
    },
    beforeUnmount() { 
        window.removeEventListener('scroll', this.updateScroll);
        window.removeEventListener('resize', this.onResize);        
    },       
};
</script>

<style lang="scss">
.foundry-safe__container {
    // width: 100vw;     
    display: flex;
    flex-flow: column;
    background: transparent;
    position: relative;
    overflow-x: hidden;   
    padding-top: 100vh; 
    &.maxTop{
        padding-top: 720px; 
        @media only screen and (min-width: 1200px) {            
            padding-top: $px-720; 
        }
    }
    .gap-2{
        @media only screen and (min-width: 1200px) {    
            gap: $px-8 !important;
        }
    }
    .fs-logo{       
        width: 142px;
        height: 24px;       
        cursor:pointer;
        @media only screen and (min-width: 1200px) {            
            width: $px-142;
            height: $px-24;
        }
    }
    .btn-fs, .btn-media{            
        min-width: 134px;
        height: 40px;
        border-radius: 8px;
        padding: 10px;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #258BAC;
        text-transform: uppercase;        
        box-shadow: none;
        outline: none;
        border: none;
        display: flex;
        gap: 8px;
        &:active, &:focus{
            box-shadow: none;
            outline: none;
            border: none;
        }    
        &:hover{
            background: #258BAC;
        }    
        svg{
            width: 20px;
            height: 20px;
        }
        @media only screen and (min-width: 1200px) {
            min-width: $px-134;
            height: $px-40;
            border-radius: $px-8;
            padding: $px-10;
            font-size: $px-14;
            gap: $px-8;
            svg{
                width: $px-20;
                height: $px-20;
            }           
        }
    }
    .btn-media{
        min-width: 40px;
        border-radius: 8px;
        background: #8BD4E9;
        color: #000;
        &:hover{
            background: #8BD4E9;
            color: #000;
        }
        @media only screen and (min-width: 1200px) {
            min-width: $px-40;
            border-radius: $px-8;
        }
    }
    .box-media{
        position: absolute; 
        right: 0; 
        top: 120%;
        display: flex;
        width: 200px;        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #FFF;
        color: #000;
        box-shadow: 0px 2px 4px 0px rgba(92, 92, 92, 0.25);
        font-family: DM Sans;
        font-style: normal;
        font-size: 14px;
        line-height: 160%;            
        .media-text{
            font-weight: 400;        
            display: flex;
            gap: 10px;
            height: 50px;
            align-items: center;    
            padding: 0px 14px;
            &.title{
                color: #B3B3B3;            
                font-weight: 700;        
                border-top: 1px solid #B3B3B3;
            }
        }
        .line-media{
            width: 100%;
            border-bottom: 1px solid #B8E9F4;
        }
        a{
            text-decoration: none;
            color: #000;
            cursor: pointer;
        }        
        svg{
            width: 20px;
            height: 20px;
        }
        @media only screen and (min-width: 1200px) {
            width: $px-200;        
            border-radius: $px-8;
            box-shadow: 0 $px-2 $px-4 0 rgba(92, 92, 92, 0.25);
            font-size: $px-14;
            .media-text{
                gap: $px-10;
                height: $px-50;
                padding: 0 $px-14;
                &.title{    
                    border-top: $px-1 solid #B3B3B3;
                }
            }  
            .line-media{            
                border-bottom: $px-1 solid #B8E9F4;
            }
            svg{
                width: $px-20;
                height: $px-20;
            }
        }
    }
    .header-safe{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        background: rgba(255, 255, 255, 0.20);
        padding: 24px 30px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        backdrop-filter: blur(5px);
        &.darker{
            background: rgba(37, 100, 120, 0.20);            
        }
        @media only screen and (min-width: 1200px) {
            padding: $px-24 $px-30 $px-20;
        }
    }
    .section-container{
        width: 100vw;
        height: 100vh; 
        height: calc(var(--vh, 1vh) * 100);
        max-height: 720px;
        position: relative;
        overflow-x: hidden;     
        @media only screen and (min-width: 1200px) {
            max-height: $px-720;
        }
    }
    #landing-safe{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        background: linear-gradient(132deg, #F2FDFF, #BFE2EA);
        background-size: 400% 400%;
        animation: BackgroundGradient 5s ease infinite;
        box-shadow: 0px -2px 10px 2px rgba(255, 255, 255, 0.25);
        @media only screen and (min-width: 1200px) {
            box-shadow: 0px $_px-2 $px-10 $px-2 rgba(255, 255, 255, 0.25);
        }
        .ls__box-content{
            width: 76vw;
            height: 507px;            
            position: absolute;
            left: 50%;
            top: 52%;
            transform: translate(-50%, -50%);
            @media only screen and (min-width: 1200px) {
                height: $px-507;                
            }
            .ls__box-container{
                display: flex;
                flex-direction: column;
                gap: 50px;
                @media only screen and (min-width: 1200px) {
                    gap: $px-50;
                }
            }            
            .ls__content-wrap{
                position: relative;                
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                gap: 15px;
                @media only screen and (min-width: 1200px) {
                    gap: $px-15;
                }
                .ls__box-text{    
                    position: relative;                
                    display: flex;
                    font-family: Poppins;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 160%;
                    background: linear-gradient(202deg, #1D4A59 0%, #276A80 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-shadow: 1px 2px 2px rgb(0 0 0 / 20%);
                    max-width: 596px;
                                       
                    svg{
                        width: 242px;
                        height: auto;
                        position: absolute;
                        bottom: 9px;
                        left: 73px;
                        filter: drop-shadow(1px 3px 2px rgb(0 0 0 / 0.2));
                    }

                    @media only screen and (min-width: 1200px) {                                                
                        font-size: $px-48;
                        text-shadow: $px-1 $px-2 $px-2 rgb(0 0 0 / 20%);
                        max-width: $px-596;
                        svg{
                            filter: drop-shadow($px-1 $px-3 $px-2 rgb(0 0 0 / 0.2));
                            width: $px-242;   
                            bottom: $px-9;
                            left: $px-73;                      
                        }
                    }
                                        
                }
                .fs-safe{
                    // position: absolute;
                    // top: 50%;
                    // right: -240px;//-287px;
                    // transform: translate(115vw, -50%);
                    width: 440px;
                    transition: transform 1.5s ease;                    
                    @media only screen and (min-width: 1200px) {                        
                        width: $px-440;
                    }
                    // &.in{
                    //     transform: translate(0, -50%);
                    // }
                }
            }
            .ls__wrap-icon{
                display: flex;
                gap: 60px;
                align-items: center;
                &.wrap-small{
                    gap: 11px;
                }
                .icon-title{
                    color: #000;
                    font-family: DM Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 160%;
                }
                img{
                    height: 45px;
                    width: auto;
                }
                @media only screen and (min-width: 1200px) {                      
                    gap: $px-60;
                    &.wrap-small{
                        gap: $px-11;
                    }
                    .icon-title{
                        font-size: $px-16;
                    }
                    img{
                        height: $px-45;
                    }
                }
            }
        }
    }
    #understanding{
        display: flex;
        background: linear-gradient(202deg, #1D4A59 0%, #276A80 100%);
        box-shadow: -2px -2px 40px 0px rgba(0, 0, 0, 0.40);        
        overflow: hidden;
        padding: 100px 150px 0;
        @media only screen and (min-width: 1200px) {
            box-shadow: $_px-2 $_px-2 $px-40 0 rgba(0, 0, 0, 0.40);
            padding: $px-100 $px-150 0;
        }
        .utp__box-img{
            flex-grow: 1;
            position: relative;
            zoom: 0.95;
            .img-utp{
                position: absolute;
                top: 0;
                right: 50%;
                transform: translateX(50%);
            }
            .img-bg{
                width: 506px;
                height: 469px;
                @media only screen and (min-width: 1200px) {
                    width: $px-506;
                    height: $px-469;
                }
            }
            .img-1{
                width: 422.787px;
                height: auto;
                @media only screen and (min-width: 1200px) {
                    width: $px-422;
                }
            }
            .img-2{
                width: 382px;
                height: auto;
                top: 14%;
                @media only screen and (min-width: 1200px) {
                    width: $px-382;
                }
            }
            .img-3{
                width: 382px;
                height: auto;
                top: 10%;
                @media only screen and (min-width: 1200px) {
                    width: $px-382;
                }
            }
            .img-transition{
                opacity:0;
                transition: opacity 0.8s ease-in-out;
            }
            .show{
                opacity:1;
            }

        }
        .utp__box-content{
            display: flex;
            flex-direction: column;            
            width: 722px;
            zoom: 0.93;
            @media only screen and (min-width: 1200px) {                
                width: $px-722;
            }    
            .utp__wrap-title{
                color: #8BD4E9;
                font-family: Poppins;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 40px;
                @media only screen and (min-width: 1200px) {
                    font-size: $px-48;
                    margin-bottom: $px-40;
                }
            }        
            .utp__wrap-text{
                display: flex;
                flex-flow: column;
                gap: 30px;                
                @media only screen and (min-width: 1200px) {
                    gap: $px-30;
                }
                .utp__box-text{
                    max-width: 484px;                    
                    font-family: DM Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    margin-left: 0;
                    color: #FFFFFF;
                    opacity: 0.30000001192092896;
                    cursor: pointer;
                    transform: scale(1);
                    transition: all 0.8s ease-in-out;
                    &.active{
                        cursor: none;
                        pointer-events: none;
                        opacity: 1;
                        margin-left: 110px;//40px;
                        transform: scale(1.21);
                        // font-size: 16px;
                        // max-width: 555px;
                        // .utp__text-title{
                        //     font-size: 34px;
                        // }
                    }
                    @media only screen and (min-width: 1200px) {
                        max-width: $px-484;                        
                        font-size: $px-14;
                        &.active{
                            margin-left: $px-110;
                            // font-size: $px-16;
                            // max-width: $px-555;
                            // .utp__text-title{
                            //     font-size: $px-34;
                            // }
                        }
                    }                    
                    .utp__text-title{
                        font-family: Poppins;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;      
                        //transition: font-size 0.8s ease-in-out;                  
                        @media only screen and (min-width: 1200px) {
                            font-size: $px-30;                            
                        }
                    }
                }
            }
        }
    }       
    #features{
        display: flex;        
        //align-items: center;
        justify-content: center;
        background: #FFFFFF;        
        //min-height: 100vh;
        height: 100%;
        overflow: hidden;
        .feat__wrap-cont{
            background: linear-gradient(132deg, #F2FDFF, #BFE2EA);
            background-size: 400% 400%;
            animation: BackgroundGradient 5s ease infinite;            
            transition: transform 1.2s ease;            
            padding: 100px 150px 75px;            
            height: 100%;
            @media only screen and (min-width: 1200px) {
                padding: $px-100 $px-150 $px-75;
            }
        }
        .feat__box-container{
            display: flex;
            gap: 40px;
            width: 100%;
            color: #000;
            @media only screen and (min-width: 1200px) {
                gap: $px-40;
            }
            .feat__box-big{
                max-width: $px-416;
                gap: $px-40;
                display: flex;
                flex-direction: column;
            }
            .feat__box-small{
                flex-grow: 1;                
                gap: $px-20;
                display: flex;
                flex-direction: column;
                height: $px-546;
                flex-wrap: wrap;
                opacity: 0;
                transform: translateY(5vh);
                transition: all 0.7s linear;
                &.in{
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            .feat__content-wrap{
                display: flex;                
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                padding: $px-20;
                gap: $px-10;
                border-radius: $px-20;
                width: $px-323;
                background: linear-gradient(180deg, #DCF8FF 39.58%, #FFF 100%, #FFF 100%);
            }
            .feat__title{                
                font-family: 'Poppins';
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                &.big{
                    font-weight: 700;
                    color: #258BAC;                    
                    font-size: 48px;                    
                }
                @media only screen and (min-width: 1200px) {
                    font-size: $px-24;
                    &.big{                        
                        font-size: $px-48;                        
                    }
                }
            }
            .feat__text{
                font-family: 'DM Sans';
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
                &.small{
                    font-weight: 400;
                    font-size: $px-14;
                    line-height: 150%;
                }
                @media only screen and (min-width: 1200px) {
                    font-size: $px-16;
                }
            }
        }
    }
    .p-bottom{
        padding-bottom: $px-62;
    }
    .fs__footer{
        display: flex;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 48px;
        background: #f2f9fb;
        box-shadow: 0 -2px 3px hsl(0deg 0% 80% / 30%);        
        background: #F2F9FB;        
        border-radius: 0;
        justify-content: center;
        align-items: center;
        z-index: 9;
        @media only screen and (min-width: 1200px) {
            height: $px-48;
            box-shadow: 0 $_px-2 $_px-3 hsl(0deg 0% 80% / 30%);  
        }
        .footer-box{
            width: 100%;//1140px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0 40px;
            @media only screen and (min-width: 1200px) {                
                margin: 0 $px-40;
            }
            .footer-display{
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                text-align: center;                                
                color: #999999;
                padding-top: 3px;
                display: flex;
                gap: 5px;
                img{
                    height: 19px;
                    width: auto;
                }
                @media only screen and (min-width: 1200px) {
                    font-size: $px-12;
                    padding-top: $px-3;
                    gap: $px-5;
                    img{
                        height: $px-19;
                    }
                }
                a{
                    text-decoration: none;
                    color: inherit;
                }
            }
            .footer-box-icon{
                display: flex;
                flex-flow: row;
                gap: $px-6;//30px;
                color: #999999;
                align-items: center;
                a{
                    color:#999999 !important;
                }
                .footer-link{                
                    display: flex;
                    flex-flow: row;
                    gap: 7px;
                    img{
                        width: 25.9px;
                        height: 25.9px;
                    }
                    @media only screen and (min-width: 1200px) {
                        gap: $px-7;
                        margin-left: $px-7;
                        img{
                            width: $px-26;
                            height: $px-26;
                        }
                    }
                }
                .footer-text-link{
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 150%;                    
                    text-transform: uppercase;
                    text-decoration: none;                    
                    @media only screen and (min-width: 1200px) {
                        font-size: $px-12;
                    }
                }
                .footer-space-line{
                    color: #999999;
                    font-size: $px-12;
                    font-family: 'Roboto';                    
                    font-weight: 700;
                    line-height: 27px;
                    letter-spacing: .05em;
                    text-align: center;
                    text-transform: uppercase;      
                    @media only screen and (min-width: 1200px) {
                        line-height: $px-27;
                    }              
                }
            }
        }
    }
}
.safe-box-enter-active, .safe-key-enter-active {
    transition: left 0.5s ease 1.5s;
}
.safe-box-enter,
.safe-box-leave-to {
    left: -72.5vw;
    //opacity: 0;
}
.safe-key-enter,
.safe-key-leave-to {
    transform: translateX(115vw);
}
@keyframes BackgroundGradient {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}
.fade-notif-leave-active {
    transition: opacity .6s ease;
}

.fade-notif-enter-active {
    transition: opacity .6s ease 0.6s;
}


.fade-notif-enter, .fade-notif-leave-to {
    opacity: 0;
}
</style>
